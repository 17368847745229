import { AccountActionTypes, AccountView } from "./types";

export const selectAccountView = (payload: AccountView): AccountActionTypes => ({
  type: 'SELECT_ACCOUNT_VIEW',
  payload
});

export const setIntersitialMode = (payload: 'r' | 'i'): AccountActionTypes => ({
  type: 'SET_INTERSITIAL_MODE',
  payload
});